import React, { useRef, useState, useEffect } from "react";
import { BsFacebook, BsLinkedin, BsYoutube } from "react-icons/bs";
import { RiInstagramFill } from "react-icons/ri";
import emailjs from "@emailjs/browser";
import Alert from "../components/alert";

export default function CoomingSoon() {
  const form = useRef();
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    if (showAlert) {
      const timer = setTimeout(() => setShowAlert(false), 3000);
      return () => clearTimeout(timer);
    }
  }, [showAlert]);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      )
      .then(
        (result) => {
          setAlertMessage("Email sent successfully");
          setShowAlert(true);
          form.current.reset();
        },
        (error) => {
          setAlertMessage("Failed to send email");
          setShowAlert(true);
        }
      );
  };

  return (
    <div className="container mx-auto px-4 py-8 min-h-screen flex flex-col md:flex-row items-center justify-center">
      <div className="flex flex-col gap-5 w-full md:w-1/2">
        <p className="text-lg md:text-xl font-medium bg-cyan-100 text-cyan-700 uppercase px-3 flex justify-center rounded-3xl py-2">
          Coming soon
        </p>
        <h2 className="text-4xl md:text-6xl font-bold text-center">
          Get Notified When I Launch My New Portfolio
        </h2>
        <p className="text-base text-center">
          "Don't worry, I won't spam you."
        </p>
        <form
          ref={form}
          onSubmit={sendEmail}
          className="flex flex-col items-center gap-4"
        >
          <input
            placeholder="Enter your email"
            type="email"
            name="from_name"
            className="border border-gray-200 focus:outline-none p-3 shadow-sm rounded-l-md w-full max-w-md"
            required
          />
          <button
            type="submit"
            className="px-6 py-3 bg-gradient-to-r from-cyan-500 to-blue-500 rounded-md hover:bg-gradient-to-l focus:outline-none focus:ring focus:ring-cyan-300 text-white font-medium shadow-lg transform transition duration-150 ease-in-out"
          >
            Notify Me
          </button>
        </form>
        {showAlert && <Alert message={alertMessage} />}
        <div className="flex justify-center gap-4 mt-4">
          <a
            href="https://www.facebook.com"
            target="_blank"
            rel="noopener noreferrer"
            className="text-cyan-600 hover:text-cyan-700"
          >
            <BsFacebook className="text-3xl md:text-4xl" />
          </a>
          <a
            href="https://www.instagram.com"
            target="_blank"
            rel="noopener noreferrer"
            className="text-pink-600 hover:text-pink-700"
          >
            <RiInstagramFill className="text-3xl md:text-4xl" />
          </a>
          <a
            href="https://www.linkedin.com"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 hover:text-blue-700"
          >
            <BsLinkedin className="text-3xl md:text-4xl" />
          </a>
        </div>
      </div>
      <div className="mt-8 md:mt-0 md:w-1/2 flex justify-center">
        <img
          src="https://img.freepik.com/free-vector/local-tourism-concept_23-2148606915.jpg?w=740&t=st=1710019704~exp=1710020304~hmac=e5a2cd899c2497f0256213ebbb9fe8e144a40adf74ba5c683510436f7b50be76"
          alt="hero"
          className="max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl"
        />
      </div>
    </div>
  );
}
