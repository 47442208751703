import "./App.css";
import React from "react";
import { BsFacebook, BsLinkedin, BsYoutube } from "react-icons/bs";
import { RiInstagramFill } from "react-icons/ri";
import CoomingSoon from "./pages/coomingSoon";

function App() {
  return (
    <div>
      <CoomingSoon />;
    </div>
  );
}

export default App;
